<template>
  <div class="w-full h-full">
    <TheTopbar />
    <router-view class="w-full" id="main" />
  </div>
</template>
<script>
import TheTopbar from "./TheTopbar";

import { mapState, mapActions } from "vuex";
export default {
  components: {
    TheTopbar,
  },
};
</script>