<template lang="html">
  <div class="flex justify-between p-6 pb-5 bg-white shadow">
    <div class="flex items-center justify-center sm:justify-start w-full">
      <img width="200" src="@/assets/mikadologo.png" />
    </div>
    <div>
      <router-link to="/logout">Logout</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "TheTopbar",
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>
